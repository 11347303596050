<template>
  <nut-navbar @on-click-back="back"
              @on-click-title="title"
              @on-click-send="send"></nut-navbar>

  <div :style="{ width: '100 %' , height: iframeHeight + 'px'}">
    <iframe :src="intenturl"
            frameborder="0"
            style="width: 100%; height: 100%"
            id="iframeBox"></iframe>
  </div>

  <!-- 底部菜单 -->
  <nut-tabbar @tab-switch="tabSwitch">
    <nut-row class="btnav">
      <nut-col :span="12">
        <nut-tabbar>
          <!--          <nut-tabbar-item
              tab-title="分享"
              icon="share"
              @click="sharePop"
          ></nut-tabbar-item>-->
          <nut-tabbar-item tab-title="联系客服"
                           icon="service"
                           @click="getChat"></nut-tabbar-item>
          <nut-tabbar-item tab-title="进入店铺"
                           icon="shop"
                           @click="goShop()"></nut-tabbar-item>
        </nut-tabbar>
      </nut-col>
      <nut-col :span="12">
        <div style="text-align: center;background-color: #3f6ee0;height: 50px;padding-top: 13px"
             @click="buyPop()">
          <span style="color: white;font-size: 15px">立即购买</span>
        </div>
      </nut-col>
    </nut-row>
  </nut-tabbar>
  <!-- 分享 -->
  <nut-popup position="bottom"
             v-model:visible="sharePopVisible">
    <div class="share-list">
      <ul>
        <li>
          <img src="../assets/img/umsocial_wechat.png"/>
          <p>微信</p>
        </li>
        <li>
          <img src="../assets/img/umsocial_wechat_timeline.png"/>
          <p>微信朋友圈</p>
        </li>
        <li>
          <img src="../assets/img/umsocial_wechat_favorite.png"/>
          <p>微信收藏</p>
        </li>
        <li>
          <img src="../assets/img/umsocial_qq.png"/>
          <p>QQ</p>
        </li>
        <li>
          <img src="../assets/img/umsocial_qzone.png"/>
          <p>QQ空间</p>
        </li>
        <li>
          <img src="../assets/img/umsocial_sina.png"/>
          <p>新浪</p>
        </li>
      </ul>
    </div>
  </nut-popup>
  <!--购买-->
  <nut-popup position="bottom"
             :closeable="false"
             round
             v-model:visible="buyPopVisible"
             :style="{ height: '80%' }">
    <div class="content">
      <nut-row :gutter="10">
        <nut-col :span="8">
          <img :src="image"
               class="prospic"/>
        </nut-col>
        <nut-col :span="15">
          <p class="price">
            <em class="text-red">￥{{ price }}</em>
            <nut-icon size="32"
                      :name="vipIcon"></nut-icon>
            <em>￥{{ vipPrice }}</em>
          </p>
        </nut-col>
        <nut-col :span="24"
                 class="form-item addressd">
          <!-- 单元格 -->
          <nut-cell title="收货地址："
                    :desc="text"
                    is-link
                    class="address-cell"
                    @click="addressClick"></nut-cell>
        </nut-col>
      </nut-row>

      <template v-if="productFashionList.length>0">
        <h3 class="detail-title">请选择规格</h3>
        <div>
          <ul class="specification-list">
            <li v-for="(productFashion, index) in productFashionList"
                :key="index"
                :class="currentindex==index?'on':''"
                @click="choseFashion(productFashion,index)">
              {{ productFashion.fashion_name }}
            </li>
          </ul>
        </div>
      </template>
      <nut-row :gutter="10">
        <nut-col :span="14">
          <h3 class="detail-title">购买数量</h3>
        </nut-col>
        <nut-col :span="10"
                 class="countbox">
          <nut-inputnumber v-model="count"/>
        </nut-col>
      </nut-row>
      <h3 class="detail-title">支付方式</h3>

      <!-- 新写法 -->
      <nut-cell-group v-for="(item,index) in data.zfList"
                      :key="index"
                      @click="getzf(item,index)"
                      class="chosezf">
        <nut-cell :title="item.title">
          <template v-slot:icon>
            <img class="nut-icon"
                 :src="item.zfpic"/>
          </template>
          <template v-slot:link
                    v-if="data.currentZf===index">
            <nut-icon name="checked"></nut-icon>
          </template>
        </nut-cell>
      </nut-cell-group>
      <!-- <nut-radiogroup v-model="radioVal5">
          <nut-radio :label="1"
                     icon-name="checklist"
                     icon-active-name="checklist">支付宝
          </nut-radio>
          <nut-radio :label="2"
                     icon-name="checklist"
                     icon-active-name="checklist">微信
          </nut-radio>
        </nut-radiogroup> -->
      <div class="blank35"/>
      <div style="position: fixed;bottom: 10px; width: 95%;">
        <nut-button block
                    type="info"
                    @click="confirmClick">确认
        </nut-button>
      </div>
    </div>
  </nut-popup>
  <!-- 我的收货地址 弹出层-->
  <nut-popup position="bottom"
             closeable
             round
             v-model:visible="showAddress"
             :style="{ height: '80%' }"
             @touchmove.prevent="false">
    <nut-navbar :style="{ background: '#fff', color: '#474e58'}"
                :left-show="false"
                title="我的收货地址"></nut-navbar>
    <div class="content address-content"
         style="overflow: hidden">
      <!--      <h3 style="margin-top: 12px;margin-left: 100px">我的收货地址</h3>-->
      <div style="margin-top: 20px">
        <ul>
          <nut-swipe v-for="(address, index) in existAddress"
                     :key="index"
                     @click="getAddressClick(address)"
                     class="addressbox">
            <template #right>
              <nut-button shape="square"
                          style="height: 100%"
                          type="info"
                          @click="reviseClick(address)">
                修改
              </nut-button>
              <nut-button shape="square"
                          style="height: 100%"
                          type="danger"
                          @click="delClick(address.receiverId)">
                删除
              </nut-button>
            </template>
            <li :class="address.defaulStatus===1?'on':'aa'">
              <!-- 新写法 -->
              <nut-icon :name="address.defaulStatus===1?'checked':'location2'"></nut-icon>
              <div class="addressinfo">
                <p> {{ address.receiverName + " " + address.receiverPhone }}</p>
                <p> {{
                    address.provinceName +
                    " " +
                    address.cityName +
                    " " +
                    address.countyName +
                    " " +
                    address.addressDetail
                  }}</p>
              </div>

            </li>
          </nut-swipe>
        </ul>
      </div>
      <div class="blank35"/>
      <div style="position: fixed;bottom: 0;z-index: 785;text-align: center;width: 96%;">
        <nut-button block
                    type="info"
                    @click="addAddress">
          添加收货地址
        </nut-button>
      </div>
    </div>
  </nut-popup>
  <!-- 添加收货地址 弹出层 -->
  <nut-popup position="bottom"
             closeable
             round
             @close="addAddressClose()"
             v-model:visible="addAddressPopup"
             :style="{ height: '80%' }">
    <nut-navbar :style="{ background: '#fff', color: '#474e58' }"
                :left-show="false"
                :title="addressTitle"></nut-navbar>
    <div class="content">
      <form :model="addressForm">
        <nut-row>
          <nut-col :span="24"
                   class="form-item">
            <nut-input placeholder="名字"
                       :require-show="true"
                       label="收货人"
                       v-model="addressForm.name"/>
          </nut-col>
          <nut-col :span="24"
                   class="form-item">
            <nut-input placeholder="手机号"
                       :require-show="true"
                       label="手机号码"
                       v-model="addressForm.phone"/>
          </nut-col>
          <nut-col :span="24"
                   class="form-item">
            <!-- 单元格 -->
            <nut-cell title="所属地区："
                      :desc="desc_two"
                      @click="open"></nut-cell>
          </nut-col>
          <nut-col :span="24"
                   class="form-item">
            <nut-input placeholder="街道、小区栋楼/乡村名称"
                       label="详细地址"
                       v-model="addressForm.detailedAddress"/>
          </nut-col>
          <nut-col :span="24"
                   class="form-item">
            <nut-cell title="是否设置为默认">
              <template v-slot:link>
                <nut-switch v-model="switchChecked"/>
              </template>
            </nut-cell>
          </nut-col>
        </nut-row>
        <nut-col :span="24"
                 class="form-item lg-btn">
          <nut-button plain
                      type="info"
                      class="blue-btn"
                      @click="saveAddressClick"
                      :loading="loadingSave">
            {{ saveContent }}
          </nut-button>
        </nut-col>
      </form>
    </div>
  </nut-popup>
  <!-- 地址 选择器 多级联动 -->
  <nut-picker v-model:visible="show"
              :list-data="listData"
              title="地址选择"
              @confirm="confirm"></nut-picker>
</template>
<script>
import {getCurrentInstance, onMounted, reactive, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {Notify, Dialog, Toast} from '@nutui/nutui';
import axios from 'axios';
import{ getCookie, setCookie } from '@/utils/cookie'
// vue
export default {
  name: 'prodetail',
  setup: function () {
    const router = useRouter();
    const route = useRoute();
    //Base64 加密解密
    let Base64 = require('js-base64').Base64;
    const {proxy} = getCurrentInstance();
    const sharePopVisible = ref(false);
    const buyPopVisible = ref(false);
    const userId = Base64.decode(route.query.userId);
         // 判断是否是来自app 0 安卓 1 苹果
    if (route.query.fromtype) {
      setCookie('fromtype', route.query.fromtype)
    }
    // 判断是否是来自app 0 安卓 1 苹果
    const fromtype = ref()
    if (getCookie('fromtype')) {
      fromtype.value = getCookie('fromtype')
    }
    console.log('fromtype: ' + fromtype.value)

    //规格ID
    const fashionId = ref('');
    // 选中规格
    const currentindex = ref(0);
    //详情页链接
    const intenturl = ref('');
    //商店链接
    const etpintentUrl = ref('');
    //商品ID
    const productId = ref(route.query.productId);
    //商品原价格
    const price = ref('');
    //vip价格
    const vipPrice = ref('');
    //图片
    const image = ref('');
    //购买数量
    let count = ref(1);
    //单选按钮值
    const radioVal5 = ref(0);
    const radioVal = ref();
    const radioVal2 = ref();
    //地址ID
    const receiveId = ref('');
    const iframeHeight = ref('900');

    function tabSwitch(item, index) {
      console.log(item, index);
    }

    let productFashionList = ref([]);
    const data = reactive({
      currentZf: 0,
      proList: [],
      zfList: [
        {
          title: '支付宝',
          zfpic: require('@/assets/img/zf1.png'),
        },
        {
          title: '微信',
          zfpic: require('@/assets/img/zf2.png'),
        },
      ],
    });
    const prod = reactive({
      page: 3,
      pic: [],
    });
    const back = event => {
      if (fromtype.value != 0 && fromtype.value != 1 ) {
        router.push({name: 'member', params: {tabMenu: 2}, query: {innerId: route.query.userId}});
      } else {
        router.replace({name: 'member', params: {tabMenu: 2}, query: {innerId: route.query.userId}});
      }
   };
    //支付方式点击getzf(item,index)
    const getzf = (item, index) => {
      console.log(item, index);
      data.currentZf = index;
    };
    //购买弹窗
    const buyPop = () => {
      buyPopVisible.value = true;
      const url = 'https://work.sview3d.com/liftcloud/productDetail/action/getProductFashion.jsp?';
      const param = new URLSearchParams();
      param.append('productinnerid', productId.value);
      proxy.$axios
          .post(url, param)
          .then(res => {
            productFashionList.value = JSON.parse(res.data.list);
            console.log(productFashionList);
            //给初始点击方法赋值
            if (productFashionList.value.length > 0) {
              choseFashion(productFashionList.value[0],currentindex.value);
            }
          })
          .catch();
    };
    // 分享弹窗
    const sharePop = () => {
      //sharePopVisible.value = true;
    };
    // 选择规格新
    const choseFashion = (obj, index) => {
      currentindex.value = index;
      fashionId.value = obj.innerId;
      price.value = obj.price;
      vipPrice.value = obj.vip_price;
    };
    // 联系客服
    const getChat = () => {
      console.log('联系客服');
      //调用七陌客服
      window.qimoChatClick();
      const qimoClientId = {
        userId: route.query.userId, // 自定义用户的唯一id，不能传空字符串、null、中文，id由字母大小写、数字及'-'和 '_'组成
        priority: false, //是否优先进线设置，非必填，值为boolean类型的true或false
        //nickName: 'test',//自定义用户昵称，定义用户昵称则userId必传
        agent: '', //指定接入坐席，必输是渠道技能组中配置的坐席，非必传
        peerId: '10027990', //指定技能组ID，后台互联网渠道技能组可以查看
        customField: {
          //扩展字段，json对象，对象中的key-value都自定义
          姓名: 'Nick',
          身份证号: '13112519930101XXXX',
        },
      };
    };
    // 跳转店铺首页
    const goShop = () => {
      const shopUrl = ref('');
      if ('' === etpintentUrl.value) {
        //获取子页面win镜像
        let contentWindow = document.getElementById('iframeBox').contentWindow;
        //调用子页面的js方法
        const productInfo = JSON.parse(contentWindow.getProductInfo());
        console.log('productInfo', productInfo);
        //获取店铺产品详情列表URL
        shopUrl.value = productInfo.etpintenturl;
      } else {
        shopUrl.value = etpintentUrl.value;
      }
      console.log('shopUrl', shopUrl.value);
      /*const shopUrl =
    "https://work.sview3d.com/mobile/enterprise/etpDetail.jsp?innerId=ba3f5b73-29a0-4ce7-9a47-f35dc9af491c&userId=null";*/
      //跳转到店铺产品列表
      router.push({
        path: 'shop',
        query: {userId: route.query.userId, shopUrl: shopUrl.value},
      });
    };
    //选择规格
    const selectProductFashion = obj => {
      console.log('选择规格', obj);
      fashionId.value = obj.innerId;
      price.value = obj.price;
      vipPrice.value = obj.vip_price;
    };

    /*==============================我的收货地址 弹出层======================================*/
    //保存按钮，可点击
    const loadingSave = ref(false);
    const saveContent = ref('保存');
    const addressForm = reactive({
      id: '',
      //姓名
      name: '',
      //手机号
      phone: '',
      //详细地址
      detailedAddress: '',
    });
    const addressTitle = ref('添加收货地址');
    //用户已存在的收货地址
    const existAddress = ref([]);
    const text = ref('请选择地址');
    const showAddress = ref(false);
    const addressClick = () => {
      showAddress.value = true;
      //获取用户收货地址
      // getReceive();
    };
    const getAddressClick = params => {
      showAddress.value = false;
      const {provinceName, cityName, countyName, townName, addressDetail} = params;
      text.value = provinceName + cityName + countyName + townName + addressDetail;
      console.log('选择的地址', text.value);
      receiveId.value = params.receiverId;
      console.log('选择的地址ID', receiveId.value);
    };
    //添加收货地址 弹出层
    const addAddressPopup = ref(false);
    //关闭新增或删除弹出层时触发
    const addAddressClose = () => {
      //打开我的收货地址弹出层
      showAddress.value = true;
    }
    const addAddress = () => {
      addressTitle.value = '添加收货地址';
      addressForm.id = '';
      addressForm.name = '';
      addressForm.phone = '';
      desc_two.value = '请选择地址';
      addressForm.detailedAddress = '';
      switchChecked.value = false;
      addAddressPopup.value = true;
    };
    //默认地址 开关
    const switchChecked = ref(false);
    //保存收货地址
    const saveAddressClick = () => {
      if ('' === addressForm.name) {
        Notify.warn('请填写收货人');
        return false;
      }
      if ('' === addressForm.phone) {
        Notify.warn('请填写手机号');
        return false;
      }
      const phoneRegular = /^1[3|4|5|7|6|8|9]\d{9}$/;
      if (!phoneRegular.exec(addressForm.phone)) {
        Notify.warn('请输入正确的手机号!');
        return false;
      }
      if ('请选择地址' === desc_two.value) {
        Notify.warn('请选择所属地址');
        return false;
      }
      //进入加载状态，不可点击
      loadingSave.value = true;
      saveContent.value = '保存中';
      //分割地址
      const descList = desc_two.value.split(' ');
      //保存
      addOrdUpdate(
          addressForm.id,
          addressForm.name,
          addressForm.phone,
          descList[0],
          descList[1],
          descList[2],
          addressForm.detailedAddress,
          switchChecked.value ? '1' : '0',
          ''
      );
    };

    //修改地址
    const reviseClick = params => {
      addressForm.id = params.receiverId;
      addressForm.name = params.receiverName;
      addressForm.phone = params.receiverPhone;
      desc_two.value = params.provinceName + ' ' + params.cityName + ' ' + params.countyName;
      addressForm.detailedAddress = params.addressDetail;
      switchChecked.value = params.selectedAddress;
      addAddressPopup.value = true;
      addressTitle.value = '修改收货地址';
    };
    //删除
    const delClick = id => {
      delReceive(id);
    };

    /*================================我的收货地址 弹出层 END ====================================*/

    /*---------------------------------地址 多级联动 start -------------------------------------------*/
    const show = ref(false);
    const desc_two = ref('请选择地址');
    const open = index => {
      show.value = true;
    };
    const confirm = res => {
      desc_two.value = res.join(' ');
    };
    const listData = ref([]);
    /*---------------------------------地址 多级联动 end-------------------------------------------*/

    /*
     * 获取用户收货地址
     */
    const getReceive = () => {
      let url = 'https://service.sview3d.com/service/receive/list?onlineid=' + onLineId;
      axios
          .get(url)
          .then(function (params) {
            console.log('getReceive()', params.data);
            if ('0' === params.data.return) {
              const arr = [];
              const list = params.data.list;
              const dataCount = ref(0);
              for (const listElement of list) {
                //先自加再赋值
                ++dataCount.value;
                const json = {};
                //主键ID
                json.id = dataCount.value;
                json.receiverId = listElement.id;
                // 地址是否默认地址
                json.defaulStatus = listElement.defaul_status;
                //收货人名称
                json.receiverName = listElement.receiver_name;
                //手机号
                json.receiverPhone = listElement.receiver_phone;
                //省份
                json.provinceName = listElement.receiver_province;
                //城市
                json.cityName = listElement.receiver_city;
                //地区/县
                json.countyName = listElement.receiver_region;
                //乡/镇的名字
                json.townName = '';
                //详细地址
                json.addressDetail = listElement.detail_address;
                //默认选中=true, (defaul_status 是否置顶  1->默认)
                json.selectedAddress = 1 === listElement.defaul_status;
                //用户id
                json.userId = listElement.user_id;
                //店铺信息ID
                json.shopId = listElement.shop_id;
                //类名
                json.classId = listElement.classId;
                //创建时间
                json.createTime = listElement.createTime;
                arr.push(json);
              }
              existAddress.value = arr;
              dataCount.value = 0;
              for (let i =0; i < arr.length; i++) {
                if (arr[i].defaulStatus === 1) {
                  text.value = arr[i].provinceName + arr[i].cityName + arr[i].countyName + arr[i].townName + arr[i].addressDetail;
                }
              }
            }
          })
          .catch(function (error) {
            console.log('getReceive()-catch,', error);
          });
    };
    //删除收货地址
    const delReceive = id => {
      let url = 'https://service.sview3d.com/service/receive/del';
      const params = new URLSearchParams();
      //收货地址主键ID
      params.append('receiverid', id);
      axios
          .post(url, params)
          .then(function (params) {
            if ('0' === params.data.return) {
              Notify.success('删除成功');
              getReceive();
            } else {
              console.log('delReceive()', params.data.msg);
            }
          })
          .catch(function (error) {
            console.log('delReceive()-catch', error);
          });
    };

    //添加地址或修改地址，根据receiverid判断，无则新增，有则更新
    const addOrdUpdate = (id, name, phone, province, city, region, address, status, shipid) => {
      let url = 'https://service.sview3d.com/service/receive/add';
      const params = new URLSearchParams();
      params.append('onlineid', onLineId);
      //主键ID ,修改数据必传，新增数据不能传
      params.append('receiverid', id);
      //收货人
      params.append('receiver_name', name);
      //手机号
      params.append('receiver_phone', phone);
      //省
      params.append('receiver_province', province);
      //城市
      params.append('receiver_city', city);
      //地区
      params.append('receiver_region', region);
      //详细地址
      params.append('detail_address', address);
      //是否置顶  1->默认,0->不是默认
      params.append('defaul_status', status);
      //店铺信息
      params.append('shipid', shipid);
      axios
          .post(url, params)
          .then(function (params) {
            if ('0' === params.data.return) {
              Notify.success('操作成功');
              console.log('addOrdUpdate()-成功返回数据', params.data.userReceive);
              //获取新的收货地址列表
              getReceive();
              //关闭新增地址弹出层
              addAddressPopup.value = false;
              //进入加载状态，不可点击
              loadingSave.value = false;
              saveContent.value = '保存';
            } else {
              console.log('addOrdUpdate()', params.data.msg);
              Dialog({
                title: '错误提示',
                content: params.data.msg,
                noCancelBtn: true,
              });
              saveContent.value = '保存';
              loadingSave.value = false;
            }
          })
          .catch(function (error) {
            console.log('addOrdUpdate()-catch', error);
            Dialog({
              title: '错误提示',
              content: error,
              noCancelBtn: true,
            });
            saveContent.value = '保存';
            loadingSave.value = false;
          });
    };
    //点击确认按钮
    const confirmClick = () => {
      if (productFashionList.value.length !== 0) {
        //数据校验,获取商品规格ID
        if ('' === fashionId.value) {
          Notify.warn('请选择产品规格!');
          return;
        }
      }
      if ('请选择地址' === text.value) {
        Notify.warn('请选择收货地址!');
        return;
      }
      if (data.currentZf === 0) {
        Notify.success('支付宝支付');
        aliPay();
      } else if (data.currentZf === 1) {
        Notify.success('微信支付');
        weChatPay();
      } else {
        Notify.warn('请选择支付方式!');
        return;
      }
    };

    //支付宝支付
    const aliPay = () => {
      Notify.primary('支付宝支付');
      let url = 'https://service.sview3d.com/service/pay/aliH5PayEtp?';
      const params = new URLSearchParams();
      //用户ID，必填
      params.append('userId', userId);
      //商品号，必填
      params.append('productid', productId.value);
      //商品个数，默认为1，可不填
      params.append('count', count.value.toString());
      //收货地址主键ID，必填
      params.append('receiveid', receiveId.value);
      //规格ID，必填
      params.append('fashionid', fashionId.value);
      //订单号，可不填
      params.append('orderno', '');
      window.location.href = url + params;
    };
    //微信支付
    const weChatPay = () => {
      // Notify.primary('微信支付');
      Toast.loading('加载中', { duration: 0 })
      let url = 'https://work.sview3d.com/pay/wechat/wechatH5Pay';
      const params = new URLSearchParams();
      //用户ID 必填
      params.append('userId', userId);
      //商品号 必填
      params.append('productid', productId.value);
      //商品个数，默认为1 可不填
      params.append('count', count.value.toString());
      //收货地址主键ID 必填
      params.append('receiveid', receiveId.value);
      //规格ID 必填
      params.append('fashionid', fashionId.value);
      axios
          .post(url, params)
          .then(function (params) {
            if ('0' === params.data.return) {
              window.location.href = params.data.mweb_url;
              Toast.hide()
            }else {
              Dialog({
                title: '错误提示',
                content: params.data.msg,
                noCancelBtn: true,
              });
              Toast.hide()
            }
          })
          .catch(function (error) {
            console.log('weChatPay()-catch', error);
          });
    };

    /****************查询用户onlineid *********************************************************************/
    let onLineId = '';
    const getOnLineId = () => {
      //let url = 'https://work.sview3d.com/phone/getOnLineId?userId=' +Base64.decode(route.query.userId) ;
      let url = 'https://work.sview3d.com/phone/getOnLineId?userId=' + userId;
      axios
          .get(url)
          .then(function (params) {
            if (true === params.data.success) {
              onLineId = params.data.onLineId;
              console.log('getOnLineId()-获取ID', onLineId);
              getReceive()
            } else {
              console.log('getOnLineId()--msg', params.data.msg);
            }
          })
          .catch(function (error) {
            console.log('getOnlineId()--catch', error);
          });
    };
    /****************查询用户onlineid end *********************************************************************/
    /****************获取商品详情*******************************************************************************/
    const getProductDetail = () => {
      let url = 'https://work.sview3d.com/mobile/product/action/getProductDetail.jsp';
      const params = new URLSearchParams();
      //ref所以需要
      params.append('innerId', productId.value);
      params.append('userId', userId);
      axios
          .post(url, params)
          .then(function (res) {
            console.log(res);
            price.value = res.data.result.price;
            image.value = res.data.result.image;
            intenturl.value = res.data.result.intenturl;
            etpintentUrl.value = res.data.result.etpintenturl;
            vipPrice.value = res.data.result.vip_price;
          })
          .catch();
    };
    /****************商品详情 end******************************************************************************/

    /***************************************************************************/
    //判断支付后的返回信息
    const payInfo = () => {
      const payState = route.query.payState !== undefined ? route.query.payState : '';
      const msg = route.query.msg !== undefined ? route.query.msg : '';
      if ('0' === payState) {
        Notify.success('支付成功', { duration: 5000 });
      } else if ('1' === payState) {
        Notify.danger('支付失败', { duration: 5000 });
      }else if('2' === payState){
        Notify.warn('正在支付中，请稍后查询订单', { duration: 5000 });
      }
    };
    /******************************************************************************/
    //根据游览器窗口大小加载高度
    const getWindowsHeight = () => {
      const windowsHeight = document.documentElement.clientHeight;
      iframeHeight.value = windowsHeight - 100;
    };

   // 点击回退事件
    const onReturn = () => {
     console.log(history)
      // window.addEventListener('popstate', function () {
      //   if (fromtype.value == 0 || fromtype.value == 1) {
      //     sessionStorage.clear();
      //     // window.history.back();
      //     // window.location.replace(`https://service.sview3d.com/service/user/toVipIntroduceTest?onlineid=${onLineId}&fromtype=1&version=9.0.0`)
      //     window.location.href = `https://service.sview3d.com/service/user/toVipIntroduceTest?onlineid=${onLineId}&fromtype=1&version=9.0.0`
      //     // window.location.href = `http://10.0.44.136/service/user/toVipIntroduceTest?onlineid=${onLineId}&fromtype=1&version=9.0.0`
      //   }
      // });
    };

    //组件加载后
    onMounted(() => {
      getWindowsHeight();
      getProductDetail();
      //获取用户onLineId
      getOnLineId();
      onReturn()
      setTimeout(() => {
      }, 2000);
      //初始化  解析json数据，获取省、市、区的数据
      const jsonData = require('@/assets/json/province_city_county.json');
      const addressList = [];
      for (let key in jsonData) {
        const addressJson = {};
        //省
        addressJson.text = jsonData[key].n;
        const city = jsonData[key].c;
        const addressList2 = [];
        for (let key2 in city) {
          const cityJson = {};
          //市
          cityJson.text = city[key2].n;
          const area = city[key2].c;
          const addressList3 = [];
          for (let key3 in area) {
            const areaJson = [];
            //区/县
            areaJson.text = area[key3].n;
            addressList3.push(areaJson);
          }
          cityJson.children = addressList3;
          addressList2.push(cityJson);
        }
        addressJson.children = addressList2;
        addressList.push(addressJson);
      }
      listData.value = addressList;
      payInfo();
    });
    return {
      prod,
      vipIcon: require('../assets/img/vip.png'),
      data,
      sharePopVisible,
      back,
      sharePop,
      tabSwitch,
      getChat,
      goShop,
      buyPopVisible,
      buyPop,
      productFashionList,
      choseFashion,
      fashionId,
      count,
      selectProductFashion,
      price,
      image,
      aliPay,
      weChatPay,
      radioVal5,
      radioVal,
      confirmClick,
      existAddress,
      currentindex,
      text,
      show,
      listData,
      open,
      confirm,
      desc_two,
      showAddress,
      addressClick,
      getAddressClick,
      radioVal2,
      addAddress,
      addAddressPopup,
      switchChecked,
      saveAddressClick,
      addressForm,
      loadingSave,
      saveContent,
      reviseClick,
      delClick,
      addressTitle,
      getProductDetail,
      intenturl,
      vipPrice,
      iframeHeight,
      getWindowsHeight,
      getzf,
      addAddressClose,
      onReturn
    };
  },
};
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}

.address-cell /deep/ .nut-cell__title {
  min-width: 80px;
}
.address-cell /deep/ .nut-cell__value {
  margin-right: 5px;
}
.address-content {
  padding-right: 0;
}
</style>
